jQuery(document).foundation();
/*
These functions make sure WordPress
and Foundation play nice together.
*/

jQuery(document).ready(function() {

    // Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

	 // Makes sure last grid item floats left
	jQuery('.archive-grid .columns').last().addClass( 'end' );

	// Adds Flex Video to YouTube and Vimeo Embeds
  jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function() {
    if ( jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5 ) {
      jQuery(this).wrap("<div class='widescreen flex-video'/>");
    } else {
      jQuery(this).wrap("<div class='flex-video'/>");
    }
  });

});

jQuery(".drop-nav").click(function() {
      jQuery('.mobile-nav').slideToggle();
    });

    jQuery(".mobile-nav a").click(function() {
          jQuery('.mobile-nav').slideToggle();
        });


// Prevent small screen page refresh sticky bug
jQuery(window).on('sticky.zf.unstuckfrom:bottom', function(e) {
  if (!Foundation.MediaQuery.atLeast('large')) {
    jQuery(e.target).removeClass('is-anchored is-at-bottom').attr('style', '');
  }
});


jQuery( window ).load(function() {
// Testimonial slider
jQuery('.testimonial-slider').show();
jQuery('.testimonial-slider').slick({
  dots: false,
  lazyLoad: 'ondemand',
  infinite: true,
  speed: 1800,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
});
});


jQuery(document).ready(function($) {
jQuery('.gallery').lightGallery({
selector: 'a[href$=".jpg"], a[href$=".jpeg"], a[href$=".png"], a[href$=".gif"]',
download: false,
});
});
